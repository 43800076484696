export class Constants {
  public static get ACTIVE(): string {
    return "1";
  }
  public static get INACTIVE(): string {
    return "2";
  }
  public static get UPCOMING(): string {
    return "3";
  }
  public static get PAUSED(): string {
    return "4";
  }
  public static get BILLING_ISSUE(): string {
    return "5";
  }
  public static get ARCHIVED(): string {
    return "6";
  }
}


export const  MembershipConstants = {
     ACTIVE                : 1,
     EXPIRED               : 2,
     UPCOMING              : 3,
     FREEZED               : 4,
     BILLING_ISSUE         : 5,
     CANCELED              : 6,
     CANCELATION_SCHEDULED : 7,
     SUSPENDED : 9
 };


 export const  SubscriptionConstants = {
     ACTIVE                : 1,
     UPCOMING              : 2,
     EXPIRED               : 3,
     CANCELED              : 4,
     CANCELATION_SCHEDULED : 5,
     PAUSED : 6,
     SUSPENDED : 7
};

 export const  CancellationConstants = {
    WAITLIST        : 1,
    LATE            : 2,
    CANCELLED       : 3,
    FREE            : 4,
    AUTO            : 5,
};

 export const  BookingStatusConstants = {
    ATTENDED        : 1,
    MISSED            : 2,
    FREE       : 3,
    LATE            : 4,
    WAITLIST            : 5,
    CANCELLED: 6,
    AUTOFREE: 7,
};


export class RefundType {
  public static get PAID(): string {
    return "1";
  }
  public static get REFUND(): string {
    return "2";
  }
  public static get PAIDDUE(): string {
    return "3";
  }
}

export class ServiceId {
  public static get SERVICE_ID_PLAN(): number {
    return 1;
  }
  public static get SERVICE_ID_PT(): number {
    return 2;
  }
  public static get SERVICE_ID_CLASS(): number {
    return 3;
  }
  public static get SERVICE_ID_PACK(): number {
    return 4;
  }
}

export const MembershipTypes = {
    PLAN : 1,
    PACK : 2,
    SUBSCRIPTION : 3,
    TOPUP: 4
};

export const AccountStatus = {
    ACTIVE : 1,
    TRIAL : 2,
    EXPIRED : 3, 
    OVERDUE : 4,
    TRIAL_ENDED: 5,
    PAUSED: 6
}

export const DiscountCustomerApplicableType = {
  EVERYONE : 1,
  SELECT_TAGS : 2,
  SELECT_MEMBERSHIP : 3,
  SELECT_EMAIL : 4,
  PRIVATE : 5
}